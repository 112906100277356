// used to designate layout styling for the footer elements (within the <footer> tags) of all pages

// set size of footer byline
.byline {
    font-size: 1.2em;
    font-style: italic;
  
    &:hover {
      font-style: normal;
    }
  }
  
  // remove hover text decoration from social media & contact buttons in bottom nav
  .sm-icons {
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  
  // automatically center social media & contact icons if in portrait orientation
  @media screen and (orientation: portrait), (max-width: 1024px) {
    .sm-icons {
      margin: auto;
    }
    .bottom-nav {
      height: 4rem;
      padding-bottom: 1.5rem;
    }
  }
  
  // style bottom nav icons and set hover behavior
  // these are tricky - if modifying size, make sure to change viewBox property in HTML
  .bottom-nav-icons {
    fill: #fff;
    height: 1.5em;
    margin: 5px;
    margin-right: 10px;
    text-decoration: none;
    width: 1.5em;
  
    &:hover {
      fill: #f0c319;
    }
  }
  