.logout {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  &:hover {
    color: red;
  }
}
